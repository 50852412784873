import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CDropdownToggle, CDropdownMenu, CDropdownHeader, CDropdownItem } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilSitemap, cilPlus, cilUser, cilOptions, cilSun, cilMoon, cilAccountLogout } from '@coreui/icons';
import cn from 'classnames';

import BaseDropdown from '@/shared/ui/dropdown/BaseDropdown';
import { PLAN_NAMES } from '@/shared/constants';
import * as actionCreators from '@/store/actions';
import { isOwner } from '@/util/permissions';
import axios from '@/util/axios-config';
import { AuthClass } from '@/util/auth';
import useAnalyticsEventTracker from '@/util/hooks/useAnalyticsEventTracker';
import { cutString } from '@/util/basic';

import styles from './HeaderDropdown.module.css';

const HeaderDropdown = ({ className }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation(['headerDropdown']);

  const dispatch = useDispatch();

  const darkMode = useSelector(state => state.view.darkMode);
  const organizations = useSelector(state => state.currentUser.organizations);
  const currentOrgId = useSelector(state => state.currentUser.currentOrganizationId);
  const ownId = useSelector(state => state.currentUser.ownId);
  const isVerified = useSelector(state => state.authorization.isVerified);
  const locations = useSelector(state => state.currentUser.locations);

  const trackGAEvent = useAnalyticsEventTracker('Top bar');

  const hasOrg = organizations.length > 0;

  let verifiedContent = null;
  if (isVerified) {

    let orgContent = null;
    orgContent = (
      <React.Fragment>
        <CDropdownHeader>{t('headerDropdown:organizations')}</CDropdownHeader>
        {hasOrg &&
          organizations.map((org) => (
            <CDropdownItem
              className={styles.organizationItem}
              key={org.id}
              onClick={() =>
                dispatch(
                  actionCreators.handleOrganiztionChange(
                    org.id,
                    pathname,
                    organizations,
                    locations,
                    navigate
                  )
                )
              }
              active={org.id === currentOrgId}
            >
              <div className={styles.dropdownItemContent}>
                <CIcon icon={cilSitemap} />
                {cutString(org.name, org.plan.name !== PLAN_NAMES.free ? 15 : 20)}
              </div>
              {org.plan?.description?.name && org.plan.name !== PLAN_NAMES.free && (
                <span
                  className={cn(
                    styles.iconPro,
                    darkMode ? styles.iconProThemeWhite : styles.iconProThemeDark
                  )}
                >
                  {org.plan.description.name.toUpperCase()}
                </span>
              )}
            </CDropdownItem>
          ))}
        {(!isOwner(organizations, ownId) || !hasOrg) && (
          <CDropdownItem
            onClick={() => {
              trackGAEvent('top_create_organization');
              navigate('/create_workspace');
            }}
          >
            <div className={styles.dropdownItemContent}>
              <CIcon icon={cilPlus} />
              {t('headerDropdown:createOrg')}
            </div>
          </CDropdownItem>
        )}
      </React.Fragment>
    );

    verifiedContent =
      <React.Fragment>
        {orgContent}
        <CDropdownHeader>
          {t('headerDropdown:account')}
        </CDropdownHeader>
        <CDropdownItem onClick={() => {
          trackGAEvent('top_profile');
          navigate('/profile');
        }}>
          <div className={styles.dropdownItemContent}>
            <CIcon icon={cilUser} />
            {t('headerDropdown:profile')}
          </div>
        </CDropdownItem>
      </React.Fragment>;
  }

  function onToggleMode() {
    dispatch(actionCreators.setColorTheme(!darkMode));
    axios.patch(
      'auth/users/me/',
      { mode: !darkMode ? 'dark' : 'light' },
      { headers: AuthClass.authHeaders() });
  }

  return (
    <BaseDropdown className={className} alignment={{ xs: 'end' }}>
      <CDropdownToggle caret={false} custom={true}>
        <CIcon className={styles.toggleIcon} size='lg' icon={cilOptions} />
      </CDropdownToggle>
      <CDropdownMenu>
        {verifiedContent}
        <CDropdownItem onClick={() => {
          trackGAEvent('top_dark_light_mode')
          onToggleMode()
        }}>
          <div className={styles.dropdownItemContent}>
            <CIcon icon={darkMode ? cilSun : cilMoon} />
            {t('headerDropdown:toggleMode')}
          </div>
        </CDropdownItem>
        <CDropdownItem className={styles.dropdownItemSeparated} onClick={() => {
          trackGAEvent('top_logout');
          dispatch(actionCreators.logOut());
          navigate('/');
        }}>
          <div className={styles.dropdownItemContent}>
            <CIcon icon={cilAccountLogout} />
            {t('headerDropdown:logout')}
          </div>
        </CDropdownItem>
      </CDropdownMenu>
    </BaseDropdown>
  );
};

export default HeaderDropdown;
