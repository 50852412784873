import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ActionButton } from '@/shared/ui/action-button';
import { PLAN_NAMES } from '@/shared/constants';
import BaseContainerCard from '@/shared/ui/contaner-card/BaseContainerCard';
import { usePermissions } from '@/processes/use-permissions';
import { useUpdatePlan } from '../index';

import styles from './PlanPopup.module.css';

const PlanPopup = ({ isPlanDefault, isOwner, plan, currentOrganization, togglePopup }) => {
  const navigate = useNavigate();

  const { t } = useTranslation(['plans']);
  const { createPortalSession } = useUpdatePlan();
  const { isCurrentOrgAdmin } = usePermissions();

  const customerId = currentOrganization.stripe_customer_id;

  return (
    <div className={styles.content}>
      <div className={styles.title}>
        <Trans i18nKey="plans:your_plan">
          Your Dashboard {{ plan: plan.description?.name?.toUpperCase() }} Plan
        </Trans>
      </div>
      <div className={styles.description}>
        <ul>
          <li>
            {isPlanDefault ? t('plans:free_storage_duration') : t('plans:pro_storage_duration')}
          </li>
          <li>
            {t('plans:connected_devices')}: <b>{currentOrganization.organization_devices_count}</b>
          </li>
          <li>
            {t('plans:prepaid_device_connections_popup')}:{' '}
            <b>{currentOrganization.plan_licenses}</b>
          </li>
        </ul>
        {isPlanDefault && isOwner && (
          <ActionButton
            onClick={(e) => {
              e.stopPropagation();
              togglePopup();
              navigate('/plan_selection');
            }}
          >
            {t('plans:upgrade')}
          </ActionButton>
        )}

        {!isOwner && isCurrentOrgAdmin && (
          <BaseContainerCard noShadow>{t('plans:contact_owner')}</BaseContainerCard>
        )}

        {!!customerId && isOwner && currentOrganization.plan.name === PLAN_NAMES.pro && (
          <ActionButton
            onClick={(e) => {
              e.stopPropagation();
              createPortalSession(customerId);
            }}
          >
            {t('plans:manage_subscription')}
          </ActionButton>
        )}

        {currentOrganization?.plan?.name === PLAN_NAMES.enterprise && isOwner && (
          <ActionButton
            onClick={(e) => {
              e.stopPropagation();
              navigate('/plan_upgrade');
            }}
          >
            {t('plans:contact_support')}
          </ActionButton>
        )}
      </div>
    </div>
  );
};

export default PlanPopup;
